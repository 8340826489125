import React, { useState } from "react"
import { Link } from "gatsby"
import { Wide } from "../components/layout"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const pay = async (amount, email, name, setError) => {
  if (window && window.plausible) {
    window.plausible('subscribe');
  }
  setError(false);
  if (amount === '') {
    setError(true);
  }
  if (email === '') {
    setError(true);
  }
  const data = {
    amount: amount,
    email: email,
    name: name
  }
  const response = await fetch('/.netlify/functions/payment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  if (response.ok) {
    const data = await response.json()
    window.location = data.redirect
  } else {
    setError(true);
  }
}

const Support = ({data, location}) => {
  const [amount, setAmount] = useState('5');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  return (
    <Layout>
      <SEO title="Vonk+" path={location.pathname} />
      <div className="bg-red-600">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-3xl">
          <h2 className="text-4xl font-sans font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">Vonk+</h2>
          <p className="mt-5 text-2xl text-red-200">Meer Vonk voor jou én voor auteurs</p>
        </div>
      </div>
      <Wide>
        <div className="max-w-3xl m-auto px-4">
          <p className="pb-4">
            Een van de doelen van Vonk is om een plek te zijn waar het genre professioneler wordt. Dat betekent dat schrijvers betaald worden voor publicatie op de site. Om dat mogelijk te maken hebben we jouw hulp nodig.
          </p>
          <p className="pb-8">
            Vonk+ is een makkelijke en veilige manier om bij te dragen aan Vonk. Betaling gaat gewoon via iDeal. Je zit nergens aan vast en voor een jaar krijg je toegang tot de magazines (het doel is twee per jaar) als ebook en nog andere voordelen. Boven alles steun je schrijvers in de fantastische genres en help je een professionelere schrijfcultuur ontstaan binnen de Nederlandstalige fantasy.
          </p>
          <div className="max-w-2xl bg-red-600 m-auto p-6 rounded text-gray-50 shadow mb-8">
            <p  className="pb-2 font-bold text-center font-sans text-xl">De voordelen van Vonk+</p>
            <ul className="rounded overflow-hidden grid gap-px max-w-lg m-auto pb-8">
              <li className="bg-red-700 py-4 px-4 flex items-center space-x-3 text-base text-white">
                <FontAwesomeIcon icon={faCheck} />
                <span>Vonk Magazine als ebook</span>
              </li>
              <li className="bg-red-700 py-4 px-4 flex items-center space-x-3 text-base text-white">
                <FontAwesomeIcon icon={faCheck} />
                <span>Steun aan schrijvers</span>
              </li>
              <li className="bg-red-700 py-4 px-4 flex items-center space-x-3 text-base text-white">
                <FontAwesomeIcon icon={faCheck} />
                <span>Toegang tot de Vonk+ Discord*</span>
              </li>
              <li className="bg-red-700 py-4 px-4 flex items-center space-x-3 text-base text-white">
                <FontAwesomeIcon icon={faCheck} />
                <span>Deelnemen aan winacties en giveaways</span>
              </li>
            </ul>
          </div>
          
          <p className="pb-8">De minimale bijdrage is €5 voor een jaar de voordelen van Vonk+, maar meer is natuurlijk welkom. Kies zelf een bedrag dat je passend vindt. Verder werkt het zoals elke andere iDeal betaling.</p>
          
          <p className="pb-8">Wil je Vonk liever op een andere manier steunen? Dat kan natuurlijk ook! Deel bijvoorbeeld je favoriete artikel op social media, of <a className="text-red-600">schrijf je in</a> voor onze nieuwsbrief.</p>
        </div>
        <div className="shadow border bg-white rounded-lg max-w-3xl mx-auto p-6 text-gray-900"> 
          <h3 className="text-red-700 font-bold text-4xl font-sans">Meld je aan voor Vonk+</h3>
          {error && <p>Oeps er ging iets mis</p>}
          <div className="flex pt-8">
            <div className="flex items-center w-full">
              <p className="font-normal flex-initial font-sans mr-2">Ik steun Vonk voor een jaar voor €</p>
              <input className="flex-1 appearance-none font-sans block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-700 focus:border-red-700 sm:text-sm" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
            </div>
          </div>
          <div className="flex flex-col">
            <input type="email" placeholder="Email" className="appearance-none font-sans block mt-4 mb-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-700 focus:border-red-700 sm:text-sm" value={email} onChange={(e) => setEmail(e.target.value)}/>
            <p className="text-sm leading-tight">We delen je emailadres niet en sturen je niet zomaar mails, zie hieronder wat je wel kunt verwachten.</p>
            <input placeholder="Naam" className="appearance-none font-sans block mt-4 mb-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-700 focus:border-red-700 sm:text-sm" value={name} onChange={(e) => setName(e.target.value)}/>
            <p className="text-sm leading-tight">Wil je op de bedankt-pagina genoemd worden, vul dan hier je naam in. Het is niet verplicht en we zijn je hoe dan ook dankbaar.</p>
            <a href="#" className="font-sans text-center inline-block mt-4 mb-2 rounded bg-red-700 text-gray-50 py-3 px-6" onClick={(event) => { event.preventDefault(); pay(amount, email, name, setError); }}>Steun Vonk voor € {amount}</a>
            <p className="text-sm leading-tight">Betaling gaat via iDeal. Door je aan te melden voor Vonk+ ga je akkoord met de <Link className="text-red-500" to="/voorwaarden">algemene voorwaarden</Link>.</p>
          </div>
        </div>
        <div className="max-w-3xl m-auto px-4 pt-6">
          <p className="pb-8">Heb je vragen of opmerkingen, stuur dan een mail naar <span className="text-red-600">tom@vonkfantasy.nl</span>.</p>
          <p className="pb-2 text-red-600">Nog wat kleine lettertjes:</p>
          <ul className="list-disc list-inside space-y-4">
            <li>Met je aanmelding geniet je voor een jaar de voordelen van Vonk+.</li>
            <li>We gebruiken je e-mailadres alleen voor zaken die te maken hebben met Vonk+, zoals het versturen van de ebooks, toegang tot Discord en we geven een seintje als het jaar voorbij is.</li>
            <li>Voor het gebruik van de Vonk+ Discord vragen we om je aan de regels te houden. Het moet een leuke plek zijn voor iedereen en het is een plaats waar we rekening houden met elkaar. Meer details vind je in de Discord.</li>
            <li>Het verschijnen van de magazines is afhankelijk van een aantal factoren. We streven naar twee per jaar en de verwachting is dat dat dit jaar zeker gaat lukken.</li>
            <li>Door je aan te melden voor Vonk+ ga je akkoord met de <Link className="text-red-500" to="/voorwaarden">algemene voorwaarden</Link>.</li>
            <li>Binnen 30 dagen niet tevreden? Dan ontvang je je geld terug. Zie <Link className="text-red-500" to="/retouren">retouren</Link> voor meer informatie.</li>
          </ul>
        </div>
      </Wide>
    </Layout>
  )
}

export default Support;
