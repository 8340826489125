/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, path }) {
  const { site, defaultImage } = useStaticQuery(
    graphql`{
  defaultImage: file(relativePath: {eq: "images/vonk-social.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 120, placeholder: NONE, layout: FIXED)
    }
  }
  site {
    siteMetadata {
      title
      description
      author
      siteUrl
    }
  }
}
`
  )

  const metaDescription = description || site.siteMetadata.description
  const siteUrl = site.siteMetadata.siteUrl
  const imageUrl = `${siteUrl}${image ? image.src : defaultImage.childImageSharp.gatsbyImageData.src}`
  const url = path && `${siteUrl}${path}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: `gnDXmiIUxsY6D3C6ZE2VeBi415wf4zYFwm0a-imRR9w`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: `@vonkfantasy`
        }
      ]
        .concat(url ? [
          {
            property: `og:url`,
            content: url,
          }
        ] : [])
        .concat(meta)}
    >
      <script defer data-domain="vonkfantasy.nl" src="https://plausible.io/js/plausible.js"></script>
      <script>{`if (window) { window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) } }`}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.object
}

export default SEO
